import React, { useState, useEffect, useRef, useMemo } from "react";
import { Row, Col } from "reactstrap";
import { connect, useDispatch } from "react-redux";
import moment from "moment";
import debounce from "lodash.debounce";

import Card from "../../../components/cards/card";
import CardHeader from "../../../components/cards/cardHeader";
import CardBody from "../../../components/cards/cardBody";
import Input from "../../../components/forms/Input";
import InputPrepend from "../../../components/forms/InputPrepend";
import Select2 from "../../../components/forms/Select2";
import Checkbox from "../../../components/forms/Checkbox";
import DynamicButton from "../../../components/forms/DynamicButton";
import { getUser } from "../../../utils/User";
import { toastError } from "../../../components/commons/toast";
import { withTrans } from "../../../i18n/withTrans";

//SERVICE
import AirportService from "../../../store/actions/master_data/airport";
import AirlineService from "../../../store/actions/master_data/airline";
import ProductService from "../../../store/actions/master_data/product";

const FlightReservation = ({ currentUrl, formik, button, t, reg_price }) => {
  const dispatch = useDispatch();
  const currentUser = getUser();
  const role = currentUser?.user?.role_code;
  const airportId = currentUser?.user?.airport_id;

  const [dataAirport, setDataAirport] = useState([]);
  const [dataAirportDomestic, setDataAirportDomestic] = useState([]);
  const [dataAirportTransfer, setDataAirportTransfer] = useState([]);
  const [dataAdminBandara, setDataAdminBandara] = useState([]);
  const [dataAirline, setDataAirline] = useState([]);
  const [dataAirlineTransfer, setDataAirlineTransfer] = useState([]);
  const [checkedCrossSelling, setCheckedCrossSelling] = useState(null);

  const [searchTextAirport, setSearchTextAirport] = useState([]);
  const [searchAirportDomestic, setSearchAirportDomestic] = useState([]);
  const [searchAirportTransfer, setSearchAirportTransfer] = useState([]);
  const [searchTextAirline, setSearchTextAirline] = useState([]);
  const [searchTextAirlineTransfer, setSearchTextAirlineTransfer] = useState([]);

  const [haveAirport, setHaveAirport] = useState(
    role === "admin_bandara" 
      ? true 
    : role === "supervisor" 
      ? true
    : role === "assistant" 
      ? true
      : false
  );
  const showCrossSelling = haveAirport && currentUrl !== 'booking-contact-center';

  const minDate =
    currentUrl === 'booking-contact-center'
      ? moment().format("YYYY-MM-DD")
        : role === "admin_pusat"
          ? moment().add(1, "days").format("YYYY-MM-DD")
          : (role === "contact_center" || role === "lead_contact_center")
          ? null
          : moment().subtract(1, 'days').format("YYYY-MM-DD");
  
  useEffect(() => {
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 10,
        search_text: searchAirportDomestic,
        id: !checkedCrossSelling ? airportId : "",
        is_service: true,
      };
      dispatch(AirportService.get(param, resolve));
    }).then((res) => {
      if (!(res && res?.data)) {
        return;
      }

      setDataAirportDomestic(res?.data);

      if (!airportId) {
        return;
      }

      let list = [];
      res.data?.map(
        (airport) =>
          (list = [
            ...list,
            {
              ...airport,
              value: airport?.id,
              label: `${airport?.code}-${airport?.city}-${airport?.name}`,
            },
          ])
      );
      setDataAdminBandara(list);

      if (checkedCrossSelling) {
        return;
      }
      
      if (formik?.values?.flight_type === 0) {
        formik.setFieldValue("airport_id_to", list[0]?.id);
        formik.setFieldValue(
          "airport_name_to", 
          `${list[0]?.code} - ${list[0]?.city} - ${list[0]?.name}`
        );
        formik.setFieldValue("airport_uniform_to", list[0]?.uniform);
      } else if (formik?.values?.flight_type === 1) {
        formik.setFieldValue("airport_id_from", list[0]?.id);
        formik.setFieldValue(
          "airport_name_from", 
          `${list[0]?.code} - ${list[0]?.city} - ${list[0]?.name}`
        );
        formik.setFieldValue("airport_uniform_from", list[0]?.uniform);
      } else if (formik?.values?.flight_type === 2) {
        formik.setFieldValue("airport_id_transfer", list[0]?.id);
        formik.setFieldValue(
          "airport_name_transfer", 
          `${list[0]?.code} - ${list[0]?.city} - ${list[0]?.name}`
        );
        formik.setFieldValue("airport_uniform_transfer", list[0]?.uniform);
      }
    });
  }, [searchAirportDomestic, formik?.values.flight_type, checkedCrossSelling]);

  useEffect(() => {
    if (formik?.values?.flight_type === 0) {
      formik.setFieldValue("flight_type_code", 5);
    } else if (formik?.values?.flight_type === 1) {
      formik.setFieldValue("flight_type_code", 6);
    } else if (formik?.values?.flight_type === 2) {
      if (
        formik?.values?.airport_uniform_from !== "Indonesia" &&
        formik?.values?.airport_uniform_to !== "Indonesia"
      ) {
        formik.setFieldValue("flight_type_code", 1);
      } else if (
        formik?.values?.airport_uniform_from !== "Indonesia" &&
        formik?.values?.airport_uniform_to === "Indonesia"
      ) {
        formik.setFieldValue("flight_type_code", 2);
      } else if (
        formik?.values?.airport_uniform_from === "Indonesia" &&
        formik?.values?.airport_uniform_to !== "Indonesia"
      ) {
        formik.setFieldValue("flight_type_code", 3);
      } else if (
        formik?.values?.airport_uniform_from === "Indonesia" &&
        formik?.values?.airport_uniform_to === "Indonesia"
      ) {
        formik.setFieldValue("flight_type_code", 4);
      }
    }
  }, [
    formik?.values?.flight_type,
    formik?.values?.airport_uniform_from,
    formik?.values?.airport_uniform_to,
  ]);

  useEffect(() => {
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 10,
        search_text: searchTextAirport,
      };
      dispatch(AirportService.get(param, resolve));
    }).then((res) => {
      setDataAirport(res?.data);
    });
  }, [searchTextAirport, formik?.values.flight_type]);

  useEffect(() => {
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 10,
        search_text: searchAirportTransfer,
      };
      dispatch(AirportService.get(param, resolve));
    }).then((res) => {
      setDataAirportTransfer(res?.data);
    });
  }, [searchAirportTransfer, formik?.values.flight_type]);

  useEffect(() => {
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 10,
        search_text: searchTextAirline,
      };
      dispatch(AirlineService.get(param, resolve));
    }).then((res) => {
      setDataAirline(res?.data);
    });
  }, [searchTextAirline]);

  useEffect(() => {
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 10,
        search_text: searchTextAirlineTransfer,
      };
      dispatch(AirlineService.get(param, resolve));
    }).then((res) => {
      setDataAirlineTransfer(res?.data);
    });
  }, [searchTextAirlineTransfer]);

  useEffect(() => {
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 9999999,
      };
      dispatch(ProductService.get(param, resolve));
    }).then((res) => {
      formik.setFieldValue("data_product_list", res?.data);
    });
  }, []);

  useEffect(() => {
    if (
      formik?.values.flight_type >= 0 &&
      formik?.values?.airport_id_from &&
      formik?.values?.airport_id_to 
    ) {
      let isInternational =
        formik?.values?.airport_uniform_from !== "Indonesia" ||
        formik?.values?.airport_uniform_to !== "Indonesia";
      let services = [];
      let availservice = "";
      let productType = 0;

      if (
        formik?.values.flight_type !== 2 &&
        formik?.values?.data_product_list?.length >= 1
      ) {
        if (isInternational) {
          services = [
            ...services,
            // {
            //   label: "FAST TRACK",
            //   name: "Fast Track",
            //   type: 3,
            // },
            {
              label: "MEET & GREET INTERNASIONAL",
              name: "Meet and Greet International",
              type: 1,
            },
          ];
        } else {
          services = [
            ...services,
            {
              label: "MEET & GREET DOMESTIC",
              name: "Meet and Greet Domestic",
              type: 2,
            },
          ];
        }
        formik.setFieldValue("list_available_services", services);
      } else {
        services = [
          ...services,
          {
            id: formik?.values?.data_product_list[2]?.id,
            label: "TRANSFER",
            name: "Transfer",
            type: 4,
          },
        ];
        formik.setFieldValue("list_available_services", services);
      }
    }
  }, [
    formik?.values?.airport_id_from,
    formik?.values?.airport_id_to,
    formik?.values.flight_type,
  ]);

  useEffect(() => {
    if (formik?.values?.product_type > 0) {
      const serviceInAvailableServices = 
        formik?.values?.list_available_services?.find(
          (item) => item?.type === formik?.values?.product_type
        );
      
      if (!serviceInAvailableServices) {
        formik.setFieldValue("product_type", 0);
      }
    }
  }, [formik?.values?.list_available_services])

  useEffect(() => {
    let date = moment().format("YYYY-MM-DD");
    let time = moment(
      `${date}, ${moment().format("HH:mm")}`
    ).diff(moment().startOf("day"), "minutes");
    let datepick =
      formik?.values?.date !== null &&
      formik?.values?.date !== "" &&
      formik?.values?.date !== undefined
        ? formik?.values?.date
        : moment().format("YYYY-MM-DD");
    let timepick = moment(`${date}, ${formik?.values?.time}`).diff(
      moment().startOf("day"),
      "minutes"
    );

    if (date === datepick) {
      if (time >= timepick) {
        formik.setFieldValue("isGoShow", true);
      } else {
        formik.setFieldValue("isGoShow", false);
      }
    } else {
      formik.setFieldValue("isGoShow", false);
    }
  }, [
    formik?.values?.date,
    formik?.values?.time,
  ]);

  const debouncedResults = debounce((value, type) => {
    if (value === "") {
      return;
    }
    if (type === "domestic") {
      setSearchAirportDomestic(value);
    }
    if (type === "transfer") {
      setSearchAirportTransfer(value);
    }
    if (type === "inter") {
      setSearchTextAirport(value);
    }
  }, 500);

  //Assign Array Data Airport International
  let airport_list = [];
  dataAirport.map((airport) => {
    airport_list.push({
      value: airport?.id,
      label: `${airport?.code}-${airport?.city}-${airport?.name} ${
        airport?.uniform === "Indonesia" ? "" : "-" + airport?.uniform
      }`,
    });
  }); 
  //if airport_list does not have the same value as selectedAirport, 
  //push selectedAirport in list airport_list
  //so airport search results can still appear in the dropdown
  const indexSameAirport = airport_list.findIndex(
    (x) => x?.value == formik?.values?.selectedAirport?.value
  );
  if (indexSameAirport === -1) {
    airport_list.push(formik?.values?.selectedAirport);
  }

  //Assign Array Data Airport Transfer
  let airport_list_transfer = [];
  dataAirportTransfer.map((airport) => {
    airport_list_transfer.push({
      value: airport?.id,
      label: `${airport?.code}-${airport?.city}-${airport?.name} ${
        airport?.uniform === "Indonesia" ? "" : "-" + airport?.uniform
      }`,
    });
  });
  //if airport_list_transfer does not have the same value as selectedAirportTransfer, 
  //push selectedAirportTransfer in list airport_list_transfer
  //so airport search results can still appear in the dropdown 
  const indexSameAirportTransfer = airport_list_transfer.findIndex(
    (x) => x.value == formik?.values?.selectedAirportTransfer.value
  );
  if (indexSameAirportTransfer === -1) {
    airport_list_transfer.push(formik?.values?.selectedAirportTransfer);
  }

  //Assign Array Data Airport Domestic
  let airport_list_indo = [];
  dataAirportDomestic.map((airport) => {
    if (airport.uniform === "Indonesia") {
      airport_list_indo.push({
        value: airport?.id,
        label: `${airport?.code}-${airport?.city}-${airport?.name} ${
          airport?.uniform === "Indonesia" ? "" : "-" + airport?.uniform
        }`,
      });
    }
  });
  //if airport_list_indo does not have the same value as selectedAirportDomestic, 
  //push selectedAirportDomestic in list airport_list_indo
  //so airport search results can still appear in the dropdown 
  const indexSameAirportDomestic = airport_list_indo.findIndex(
    (x) => x?.value === formik?.values?.selectedAirportDomestic?.value
  );
  if (indexSameAirportDomestic === -1) {
    airport_list_indo.push(formik?.values?.selectedAirportDomestic);
  } 

  let airline_list = [];
  dataAirline.map((airline) => {
    airline_list.push({
      value: airline?.name,
      code: airline?.code,
      label: `${airline?.code} - ${airline?.name}`,
    });
  });
  //if airline_list does not have the same value as selectedAirline, 
  //push selectedAirline in list airline_list
  //so airport search results can still appear in the dropdown 
  const indexSameAirline = airline_list.findIndex(
    (x) => x.value == formik?.values?.selectedAirline.value
  );
  if (indexSameAirline === -1) {
    airline_list.push(formik?.values?.selectedAirline);
  }

  let airline_transfer = [];
  dataAirlineTransfer.map((airline) => {
    airline_transfer.push({
      value: airline?.name,
      code: airline?.code,
      label: `${airline?.code} - ${airline?.name}`,
    });
  });
  //if airline_transfer does not have the same value as selectedAirlineTransfer, 
  //push selectedAirlineTransfer in list airline_transfer
  //so airport search results can still appear in the dropdown 
  const indexSameAirlineTransfer = airline_transfer.findIndex(
    (x) => x.value == formik?.values?.selectedAirlineTransfer.value
  );
  if (indexSameAirlineTransfer === -1) {
    airline_transfer.push(formik?.values?.selectedAirlineTransfer);
  };
  
  const handleFlightType = (value) => {
    formik.setFieldValue("airplane_code", null);
    formik.setFieldValue("airplane_code_transfer", null);
    formik.setFieldValue("airplane_name", null);
    formik.setFieldValue("airplane_number", null);
    formik.setFieldValue("airport_id_transfer", null);
    formik.setFieldValue("airport_name_transfer", null);
    formik.setFieldValue("airport_uniform_transfer_to", null);
    formik.setFieldValue("date", null);
    formik.setFieldValue("time", null);
    setSearchAirportDomestic("");
    setSearchAirportTransfer("");
    setSearchTextAirport("");
    setSearchTextAirline("");
    setSearchTextAirlineTransfer("");
    switch (value) {
      case "arrival":
        formik.setFieldValue("list_available_services", []);
        formik.setFieldValue("flight_type", 0);
        formik.setFieldValue("airport_id_from", null);
        formik.setFieldValue("airport_name_from", null);
        formik.setFieldValue("airport_uniform_from", null);
        if (
          !checkedCrossSelling && haveAirport
        ) {
          formik.setFieldValue("airport_id_to", dataAdminBandara[0]?.id);
          formik.setFieldValue(
            "airport_name_to",
            `${dataAdminBandara[0]?.code} - ${
              dataAdminBandara[0]?.city} - ${
              dataAdminBandara[0]?.name}`
          );
          formik.setFieldValue(
            "airport_uniform_to",
            dataAdminBandara[0]?.uniform
          );
        } else {
          formik.setFieldValue("airport_id_to", null);
          formik.setFieldValue("airport_name_to", null);
          formik.setFieldValue("airport_uniform_to", null);
        }
        break;
      case "departure":
        formik.setFieldValue("list_available_services", []);
        formik.setFieldValue("flight_type", 1);
        formik.setFieldValue("airport_id_to", null);
        formik.setFieldValue("airport_name_to", null);
        formik.setFieldValue("airport_uniform_to", null);
        if (
          !checkedCrossSelling && haveAirport
        ) {
          formik.setFieldValue("airport_id_from", dataAdminBandara[0]?.id);
          formik.setFieldValue(
            "airport_name_from",
            `${dataAdminBandara[0]?.code} - ${
              dataAdminBandara[0]?.city} - ${
              dataAdminBandara[0]?.name}`
          );
          formik.setFieldValue(
            "airport_uniform_from",
            dataAdminBandara[0]?.uniform
          );
        } else {
          formik.setFieldValue("airport_id_from", null);
          formik.setFieldValue("airport_name_from", null);
          formik.setFieldValue("airport_uniform_from", null);
        }
        break;
      case "transfer":
        formik.setFieldValue("list_available_services", [
          {
            id:
              formik?.values?.data_product_list &&
              formik?.values?.data_product_list[2] &&
              formik?.values?.data_product_list[2]?.id,
            name: "Transfer",
            label: "TRANSFER",
            type: 4,
          },
        ]);
        formik.setFieldValue("flight_type", 2);
        formik.setFieldValue("airport_id_transfer", null);
        formik.setFieldValue("airport_uniform_transfer_to", null);
        formik.setFieldValue("airport_id_from", null);
        formik.setFieldValue("airport_id_to", null);
        formik.setFieldValue("airport_name_from", null);
        formik.setFieldValue("airport_name_to", null);
        formik.setFieldValue("airport_uniform_from", null);
        formik.setFieldValue("airport_uniform_to", null);
        if (
          !checkedCrossSelling && haveAirport
        ) {
          formik.setFieldValue("airport_id_transfer", dataAdminBandara[0]?.id);
          formik.setFieldValue(
            "airport_name_transfer",
            `${dataAdminBandara[0]?.code} - ${
              dataAdminBandara[0]?.city} - ${
              dataAdminBandara[0]?.name}`
          );
          formik.setFieldValue(
            "airport_uniform_transfer",
            dataAdminBandara[0]?.uniform
          );
        } else {
          formik.setFieldValue("airport_id_transfer", null);
          formik.setFieldValue("airport_name_transfer", null);
          formik.setFieldValue("airport_uniform_transfer", null);
        }
        break;
      default:
        formik.setFieldValue("flight_type", 0);
        break;
    }
  };

  const handleProductSelected = (item) => {
    let productType = formik.values.product_type;
    let selectedType = item?.type;
    let selectedName = item?.name;

    if (selectedType) {
      if (productType === selectedType) {
        selectedType = null;
        selectedName = null;
      }
    }
    formik.setFieldValue("available_service", selectedName);
    formik.setFieldValue("product_type", selectedType);
  };

  return (
    <Row>
      <Col md={9}>
        {showCrossSelling && (
          <Card rounded>
            <CardHeader title={t("field.cross_selling")} />
            <CardBody>
              <Checkbox
                name="is_cross_selling"
                desc={formik?.values?.airport_cross_selling}
                checked={formik?.values?.is_cross_selling}
                onChange={(e) => {
                  if (formik?.values?.is_cross_selling) {
                    formik?.setFieldValue("is_cross_selling", null);
                    setCheckedCrossSelling(false);
                    formik?.setFieldValue("airport_cross_selling", null);
                    setSearchAirportDomestic("");
                  } else {
                    formik.setFieldValue("is_cross_selling", ["1"]);
                    setCheckedCrossSelling(true);
                    formik?.setFieldValue(
                      "airport_cross_selling",
                      `${currentUser?.user?.airport_code} - ${
                        currentUser?.user?.airport_city} - ${
                        currentUser?.user?.airport_name}`
                    );
                    setSearchAirportDomestic("");
                    if (formik?.values?.flight_type === 0) {
                      formik.setFieldValue("airport_id_to", "");
                    } else if (formik?.values?.flight_type === 1) {
                      formik.setFieldValue("airport_id_from", "");
                    } else if (formik?.values?.flight_type === 2) {
                      formik.setFieldValue("airport_id_transfer", "");
                    }
                  }
                }}
                value={1}
                label={t("field.labelIsCrossSelling")}
                warning={t("commons.validationCrossSelling")}
              />
            </CardBody>
          </Card>
        )}
        <Card rounded>
          <CardHeader title={t("field.flightType")} />
          <CardBody>
            <div className="d-flex justify-content-left">
              <DynamicButton
                titleLeft={t("field.arrival")}
                iconLeft="flight_land"
                active={formik.values.flight_type === 0}
                toggle={() => handleFlightType("arrival")}
                errors={formik.error}
                touched={formik.touched}
                onBlur={formik.setFieldTouched}
                name="flightArv"
              />
              <DynamicButton
                titleLeft={t("field.departure")}
                iconLeft="flight_takeoff"
                active={formik.values.flight_type === 1}
                toggle={() => handleFlightType("departure")}
                errors={formik.errors}
                touched={formik.touched}
                onBlur={formik.setFieldTouched}
              />
              <DynamicButton
                titleLeft="Transfer"
                iconLeft="connecting_airports"
                iconLeftStyle="36px"
                active={formik.values.flight_type === 2}
                toggle={() => handleFlightType("transfer")}
                errors={formik.errors}
                touched={formik.touched}
                onBlur={formik.setFieldTouched}
              />
            </div>
          </CardBody>
        </Card>
        {formik.values.flight_type !== 2 ? (
          <Card rounded>
            <CardHeader title={t("field.flightInformation")} />
            <CardBody>
              <div className="d-flex justify-content-between">
                <div className="text-bold">{t("field.origin")}</div>
                <Col md={8}>
                  <Select2
                    name="airport_id_from"
                    options={
                      formik?.values?.flight_type !== 1
                        ? airport_list
                        : !checkedCrossSelling && haveAirport
                          ? dataAdminBandara
                          : airport_list_indo
                    }
                    errors={formik.errors}
                    touched={formik.touched}
                    value={formik.values?.airport_id_from}
                    onInputChange={(keyword) => {
                      debouncedResults(
                        keyword,
                        formik?.values?.flight_type !== 1 ? "inter" : "domestic"
                      );
                    }}
                    onChange={(name, value) => {
                      if (formik.values?.airport_id_to === value) {
                        toastError(`${t("field.cantSameAirport")}!`);
                        return;
                      }

                      const list_airports =
                        formik?.values?.flight_type !== 1
                          ? dataAirport
                          : !checkedCrossSelling && haveAirport
                            ? dataAdminBandara
                            : dataAirportDomestic;
                      const index = list_airports
                          ?.map(function (e) {
                            return e.id;
                          })
                          .indexOf(value);

                      formik.setFieldValue("airport_id_from", value);
                      formik.setFieldValue(
                        "airport_name_from",
                        `${list_airports[index]?.code} - ${
                          list_airports[index]?.city} - ${
                          list_airports[index]?.name}`
                      );
                      formik.setFieldValue(
                        "airport_uniform_from",
                        list_airports[index]?.uniform
                      );
                      formik?.setFieldValue(
                        formik?.values?.flight_type !== 1 
                          ? "selectedAirport" 
                          : "selectedAirportDomestic", 
                        {
                          value: value,
                          label: 
                            `${list_airports[index]?.code} - ${
                              list_airports[index]?.city} - ${
                              list_airports[index]?.name} ${
                              list_airports[index]?.uniform === "Indonesia"
                                ? ""
                                : `- ${list_airports[index]?.uniform}`
                            }`,
                        }
                      );
                    }}
                    onBlur={formik.setFieldTouched}
                  />
                </Col>
              </div>
              <div className="d-flex justify-content-between">
                <div className="text-bold">{t("field.destination")}</div>
                <Col md={8}>
                  <Select2
                    name="airport_id_to"
                    errors={formik.errors}
                    options={
                      formik.values?.flight_type !== 0 
                      ? airport_list
                      : !checkedCrossSelling && haveAirport
                        ? dataAdminBandara
                        : airport_list_indo
                    }
                    touched={formik.touched}
                    value={formik.values?.airport_id_to}
                    onInputChange={(keyword) => {
                      debouncedResults(
                        keyword, 
                        formik.values?.flight_type !== 0 ? "inter" : "domestic"
                      );
                    }}
                    onChange={(name, value) => {
                      if (formik.values?.airport_id_from === value) {
                        toastError(`${t("field.cantSameAirport")}!`);
                        return;
                      }

                      const list_airports = 
                        formik.values?.flight_type !== 0 
                        ? dataAirport
                        : !checkedCrossSelling && haveAirport
                          ? dataAdminBandara
                          : dataAirportDomestic
                      const index = list_airports
                        ?.map(function (e) {
                          return e.id;
                        })
                        .indexOf(value);

                      formik.setFieldValue("airport_id_to", value);
                      formik.setFieldValue(
                        "airport_name_to",
                        `${list_airports[index]?.code} - ${
                          list_airports[index]?.city} - ${
                          list_airports[index]?.name}`
                      );
                      formik.setFieldValue(
                        "airport_uniform_to",
                        list_airports[index]?.uniform
                      );
                      formik?.setFieldValue(
                        formik.values?.flight_type !== 0 ? "selectedAirport" : "selectedAirportDomestic", 
                        {
                          value: value,
                          label:
                            `${list_airports[index]?.code} - ${
                              list_airports[index]?.city} - ${
                              list_airports[index]?.name} ${
                              list_airports[index]?.uniform === "Indonesia"
                                ? ""
                                : `- ${list_airports[index]?.uniform}`
                            }`,
                        }
                      );
                    }}
                    onBlur={formik.setFieldTouched}
                  />
                </Col>
              </div>
            </CardBody>
            <hr />
            <CardBody>
              <div className="row">
                <Col md={6}>
                  <Select2
                    title={t("field.airline")}
                    name="airplane_name"
                    options={airline_list}
                    errors={formik.errors}
                    touched={formik.touched}
                    value={formik.values?.airplane_name}
                    onInputChange={(keyword) => {
                      if (keyword !== "") {
                        setSearchTextAirline(keyword);
                      }
                    }}
                    onChange={(name, value) => {
                      formik.setFieldValue("airplane_name", value);
                      let airplaneCode = airline_list.filter(function (el) {
                        return el.value == value;
                      });
                      formik.setFieldValue(
                        "airplane_code",
                        airplaneCode[0]?.code
                      );
                      formik.setFieldValue("selectedAirline", {
                        value: value,
                        code: airplaneCode[0]?.code,
                        label: `${airplaneCode[0]?.code} - ${value}`,
                      });
                    }}
                    onBlur={formik.setFieldTouched}
                  />
                </Col>
                <Col md={6}>
                  {/* <Input
                    title={t("field.flightNumber")}
                    name="airplane_number"
                    errors={formik.errors}
                    touched={formik.touched}
                    {...formik.getFieldProps("airplane_number")}
                  /> */}
                  <div className="form-group" style={{ marginBottom: "0" }}>
                    <label
                      className="form-label"
                      style={{ marginBottom: "5px" }}
                    >
                      {t("field.flightNumber")}
                    </label>
                  </div>
                  <InputPrepend
                    name="airplane_number"
                    label={t("field.flightNumber")}
                    title={formik.values.airplane_code}
                    type="text"
                    width="25%"
                    mt="2"
                    touched={formik.touched}
                    {...formik.getFieldProps("airplane_number")}
                  />
                </Col>
                <Col md={6}>
                  <Input
                    title={
                      formik.values.flight_type !== 1
                        ? t("field.arrivalDate")
                        : t("field.departureDate")
                    }
                    name="date"
                    type="date"
                    min={
                      checkedCrossSelling
                        ? moment().add(1, "days").format("YYYY-MM-DD")
                        : minDate
                    }
                    errors={formik.errors}
                    touched={formik.touched}
                    {...formik?.getFieldProps("date")}
                    value={formik.values?.date}
                  />
                </Col>
                <Col md={6}>
                  <Input
                    title={
                      formik.values.flight_type !== 1
                        ? t("field.arrivalTime")
                        : t("field.departureTime")
                    }
                    name="time"
                    errors={formik.errors}
                    touched={formik.touched}
                    value={formik.values.time}
                    onChange={(e) => {
                      let value = e.target.value;
                      formik.setFieldValue("time", value);
                    }}
                    type="time"
                  />
                </Col>
              </div>
            </CardBody>
          </Card>
        ) : (
          <Card rounded>
            <CardHeader title={t("field.arrivalInformation")} />
            <CardBody>
              <div className="d-flex justify-content-between">
                <div className="text-bold">{t("field.origin")}</div>
                <Col md={8}>
                  <Select2
                    name="airport_id_from"
                    options={airport_list}
                    errors={formik.errors}
                    touched={formik.touched}
                    value={formik.values?.airport_id_from}
                    onInputChange={(keyword) => {
                      debouncedResults(keyword, "inter");
                    }}
                    onChange={(name, value) => {
                      if (
                        formik.values?.airport_id_to === value ||
                        formik.values?.airport_id_transfer === value
                      ) {
                        toastError(`${t("field.cantSameAirport")}!`);
                        return;
                      }
                      formik.setFieldValue("airport_id_from", value);
                      const index = dataAirport
                        ?.map(function (e) {
                          return e.id;
                        })
                        .indexOf(value);

                      formik.setFieldValue(
                        "airport_name_from",
                        `${dataAirport[index]?.code} - ${
                          dataAirport[index]?.city} - ${
                          dataAirport[index]?.name}`
                      );
                      formik.setFieldValue(
                        "airport_uniform_from",
                        dataAirport[index]?.uniform
                      );
                      formik?.setFieldValue(
                        "selectedAirport", 
                        {
                          value: value,
                          label:
                            `${dataAirport[index]?.code} - ${
                              dataAirport[index]?.city} - ${
                              dataAirport[index]?.name} ${
                              dataAirport[index]?.uniform === "Indonesia"
                                ? ""
                                :`- ${dataAirport[index]?.uniform}`
                            }`,
                        }
                      );
                    }}
                    onBlur={formik.setFieldTouched}
                  />
                </Col>
              </div>
              <div className="d-flex justify-content-between">
                <div className="text-bold">Transfer</div>
                <Col md={8}>
                  <Select2
                    name="airport_id_transfer"
                    errors={formik.errors}
                    options={
                      !checkedCrossSelling && haveAirport
                        ? dataAdminBandara
                        : airport_list_indo
                    }
                    touched={formik.touched}
                    value={formik.values?.airport_id_transfer}
                    onInputChange={(keyword) => {
                      debouncedResults(keyword, "domestic");
                    }}
                    onChange={(name, value) => {
                      if (
                        formik.values.airport_id_to === value ||
                        formik.values.airport_id_from === value
                      ) {
                        toastError(`${t("field.cantSameAirport")}!`);
                        return;
                      }

                      formik.setFieldValue("airport_id_transfer", value);
                      const list_airports = 
                        !checkedCrossSelling && haveAirport
                          ? dataAdminBandara
                          : dataAirportDomestic
                      const index = list_airports
                        ?.map(function (e) {
                          return e.id;
                        })
                        .indexOf(value);

                      formik.setFieldValue(
                        "airport_name_transfer",
                        `${list_airports[index]?.code} - ${
                          list_airports[index]?.city} - ${
                          list_airports[index]?.name}`
                      );
                      formik.setFieldValue("airport_uniform_transfer", "");
                      formik.setFieldValue(
                        "airport_uniform_transfer",
                        list_airports[index]?.uniform
                      );
                      formik?.setFieldValue(
                        "selectedAirportDomestic", 
                        {
                          value: value,
                          label:
                            `${list_airports[index]?.code} - ${
                              list_airports[index]?.city} - ${
                              list_airports[index]?.name} ${
                              list_airports[index]?.uniform ===
                              "Indonesia"
                                ? ""
                                : `- ${list_airports[index]?.uniform}`
                            }`,
                        }
                      );
                    }}
                    onBlur={formik.setFieldTouched}
                  />
                </Col>
              </div>
            </CardBody>
            <hr />
            <CardBody>
              <div className="row">
                <Col md={6}>
                  <Select2
                    title={t("field.airline")}
                    name="airplane_name"
                    options={airline_list}
                    errors={formik.errors}
                    touched={formik.touched}
                    value={formik.values?.airplane_name}
                    onInputChange={(keyword) => {
                      if (keyword !== "") {
                        setSearchTextAirline(keyword);
                      }
                    }}
                    onChange={(name, value) => {
                      formik.setFieldValue("airplane_name", value);
                      let airplaneCode = airline_list.filter(function (el) {
                        return el.value == value;
                      });
                      formik.setFieldValue(
                        "airplane_code",
                        airplaneCode[0]?.code
                      );
                      formik.setFieldValue("selectedAirline", {
                        value: value,
                        code: airplaneCode[0]?.code,
                        label: `${airplaneCode[0]?.code} - ${value}`,
                      });
                    }}
                    onBlur={formik.setFieldTouched}
                  />
                </Col>
                <Col md={6}>
                  {/* <Input
                    title={t("field.flightNumber")}
                    name="airplane_number"
                    errors={formik.errors}
                    touched={formik.touched}
                    {...formik.getFieldProps("airplane_number")}
                  /> */}
                  <div className="form-group" style={{ marginBottom: "0" }}>
                    <label
                      className="form-label"
                      style={{ marginBottom: "5px" }}
                    >
                      {t("field.flightNumber")}
                    </label>
                  </div>
                  <InputPrepend
                    name="airplane_number"
                    label={t("field.flightNumber")}
                    title={formik.values.airplane_code}
                    type="text"
                    width="25%"
                    mt="2"
                    touched={formik.touched}
                    {...formik.getFieldProps("airplane_number")}
                  />
                </Col>
                <Col md={6}>
                  <Input
                    title={t("field.arrivalDate")}
                    name="date"
                    errors={formik.errors}
                    touched={formik.touched}
                    {...formik.getFieldProps("date")}
                    min={
                      checkedCrossSelling
                        ? moment().add(1, "days").format("YYYY-MM-DD")
                        : minDate
                    }
                    type="date"
                  />
                </Col>
                <Col md={6}>
                  <Input
                    title={t("field.arrivalTime")}
                    name="time"
                    errors={formik.errors}
                    touched={formik.touched}
                    {...formik.getFieldProps("time")}
                    type="time"
                  />
                </Col>
              </div>
            </CardBody>
            <hr />
            <CardHeader title={t("field.departureInformation")} />
            <CardBody>
              <div className="d-flex justify-content-between">
                <div className="text-bold">{t("field.destination")}</div>
                <Col md={8}>
                  <Select2
                    name="airport_id_to"
                    options={airport_list_transfer}
                    errors={formik.errors}
                    touched={formik.touched}
                    value={formik.values?.airport_id_to}
                    onInputChange={(keyword) => {
                      debouncedResults(keyword, "transfer");
                    }}
                    onChange={(name, value) => {
                      if (
                        formik.values?.airport_id_from === value ||
                        formik.values?.airport_id_transfer === value
                      ) {
                        toastError(`${t("field.cantSameAirport")}!`);
                        return;
                      }

                      formik.setFieldValue("airport_id_to", value);
                      const index = dataAirportTransfer
                        ?.map(function (e) {
                          return e.id;
                        })
                        .indexOf(value);

                      formik.setFieldValue(
                        "airport_name_to",
                        `${dataAirportTransfer[index]?.code} - ${
                          dataAirportTransfer[index]?.city} - ${
                          dataAirportTransfer[index]?.name}`
                      );
                      formik.setFieldValue(
                        "airport_uniform_to",
                        dataAirportTransfer[index]?.uniform
                      );
                      formik?.setFieldValue(
                        "selectedAirportTransfer", 
                        {
                          value: value,
                          label:
                            `${dataAirportTransfer[index]?.code} - ${
                              dataAirportTransfer[index]?.city} - ${
                              dataAirportTransfer[index]?.name} ${
                              dataAirportTransfer[index]?.uniform === "Indonesia"
                                ? ""
                                : `- ${dataAirportTransfer[index]?.uniform}`
                            }`,
                        }
                      );
                    }}
                    onBlur={formik.setFieldTouched}
                  />
                </Col>
              </div>
            </CardBody>
            <hr />
            <CardBody>
              <div className="row">
                <Col md={6}>
                  <Select2
                    title={t("field.airline")}
                    name="airplane_name_transfer_to"
                    options={airline_transfer}
                    errors={formik.errors}
                    touched={formik.touched}
                    value={formik.values?.airplane_name_transfer_to}
                    onInputChange={(keyword) => {
                      if (keyword !== "") {
                        setSearchTextAirlineTransfer(keyword);
                      }
                    }}
                    onChange={(name, value) => {
                      formik.setFieldValue("airplane_name_transfer_to", value);
                      let airplaneTransferCode = airline_transfer.filter(
                        function (el) {
                          return el.value == value;
                        }
                      );
                      formik.setFieldValue(
                        "airplane_code_transfer",
                        airplaneTransferCode[0]?.code
                      );
                      formik.setFieldValue("selectedAirlineTransfer", {
                        value: value,
                        code: airplaneTransferCode[0]?.code,
                        label: `${airplaneTransferCode[0]?.code} - ${value}`,
                      });
                    }}
                    onBlur={formik.setFieldTouched}
                  />
                </Col>
                <Col md={6}>
                  <div className="form-group" style={{ marginBottom: "0" }}>
                    <label
                      className="form-label"
                      style={{ marginBottom: "5px" }}
                    >
                      {t("field.flightNumber")}
                    </label>
                  </div>
                  <InputPrepend
                    name="airplane_number_transfer_to"
                    label={t("field.flightNumber")}
                    title={formik.values.airplane_code_transfer}
                    type="text"
                    width="25%"
                    mt="2"
                    errors={formik.errors}
                    touched={formik.touched}
                    {...formik.getFieldProps("airplane_number_transfer_to")}
                  />
                </Col>
                <Col md={6}>
                  <Input
                    title={t("field.departureDate")}
                    name="date_transfer_to"
                    errors={formik.errors}
                    min={formik.values?.date}
                    touched={formik.touched}
                    value={formik.values?.date_transfer_to}
                    {...formik.getFieldProps("date_transfer_to")}
                    type="date"
                    onChange={(e) => {
                      let value = e.target.value;
                      formik.setFieldValue("date_transfer_to", value);
                    }}
                    disabled={!formik.values.date || !formik.values.time}
                  />
                </Col>
                <Col md={6}>
                  <Input
                    title={t("field.departureTime")}
                    name="time_transfer_to"
                    errors={formik.errors}
                    touched={formik.touched}
                    value={formik.values?.time_transfer_to}
                    onChange={(e) => {
                      let value = e.target.value;
                      formik.setFieldValue("time_transfer_to", value);
                    }}
                    disabled={
                      formik.values.date_transfer_to === null ||
                      formik.values.date_transfer_to === "" ||
                      formik.values.date_transfer_to === undefined
                    }
                    type="time"
                  />
                </Col>
              </div>
            </CardBody>
          </Card>
        )}
        <Card rounded>
          <CardHeader title={t("field.product")} />
          <CardBody>
            {formik?.values?.list_available_services?.map((item, index) => {
              return (
                <React.Fragment key={`service-${index}`}>
                  <div
                    onClick={() => handleProductSelected(item)}
                    style={{ cursor: "pointer" }}
                  >
                    <Card rounded>
                      <CardBody
                        isActive={item?.type === formik?.values.product_type}
                      >
                        <div>
                          <span
                            className={
                              item?.type === formik?.values.product_type
                                ? "card-header"
                                : "card-header-inactive"
                            }
                          >
                            <span
                              style={{
                                borderRadius: "50%",
                                backgroundColor: "#63AE5C",
                                borderColor: "#63AE5C",
                                marginRight: "25px",
                                height: "100%",
                                color: "#FFFF",
                              }}
                              className="material-icons-round"
                            >
                              {item?.type === formik?.values.product_type
                                ? "done"
                                : "info"}
                            </span>
                            <div>
                              {item?.label}
                              <div
                                style={{
                                  fontSize: 12,
                                  display: "block",
                                  lineHeight: "1rem",
                                  paddingTop: 10,
                                }}
                              >
                                {t(
                                  `product.${
                                    item?.type === 3
                                      ? "detailFTDesc"
                                      : item?.type === 4
                                      ? "detailTransferDesc"
                                      : "detailMnGDesc"
                                  }`
                                )}
                              </div>
                            </div>
                          </span>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                </React.Fragment>
              );
            })}
            {(!formik?.values?.list_available_services ||
              formik?.values?.list_available_services?.length < 1) && (
              <Card rounded>
                <CardBody isActive={false}>
                  <span className="card-header-inactive">
                    <span
                      style={{
                        borderRadius: "50%",
                        backgroundColor: "#FFFF",
                        borderColor: "#A5A5C0",
                        marginRight: "25px",
                        height: "100%",
                        color: "#A5A5C0",
                      }}
                      className="material-icons-round"
                    >
                      info
                    </span>
                    {t("field.notAvailable")}
                  </span>
                </CardBody>
              </Card>
            )}
          </CardBody>
        </Card>
        {button()}
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ airport, corporate, airline, reg_price }) => {
  return { airport, corporate, airline, reg_price };
};

const mapDispatchToProps = () => {
  return {};
};

export default withTrans(
  connect(mapDispatchToProps, mapStateToProps)(FlightReservation)
);
